import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom';

import { Link } from 'react-router-dom';

import Template from '../pages/Template/Template';

// import ExamplePage from '../pages/_ExamplePage/ExamplePage';
import AboutPage from '../pages/AboutPage/AboutPage';
import ResumePage from '../pages/ResumePage/ResumePage';
import ContactPage from '../pages/ContactPage/ContactPage';

class Router extends Component {
    constructor(props) {
        super(props);

        this.DefaultState = {}
        this.state = this.DefaultState;
    }

    render() {

        return (
            <React.Fragment>

                <Switch>

                    <Route exact path="/">
                        <Template title="Home">
                            <AboutPage />
                        </Template>
                    </Route>

                    <Route exact path="/resume">
                        <Template title="Resume">
                            <ResumePage />
                        </Template>
                    </Route>

                    <Route exact path="/contact">
                        <Template title="Contact">
                            <ContactPage />
                        </Template>
                    </Route>

                    <Route path="*">
                        <Template title="404">
                            <div className="not-found-page">
                                <p className="shrug">¯\_(ツ)_/¯</p>
                                <p>404: Nothing's here</p>
                                <Link to="/">Get me outta here!</Link>
                            </div>
                        </Template>
                    </Route>

                </Switch>

            </React.Fragment>
        );
    }
}

export default Router;